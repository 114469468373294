<template>
  <div class="atom-account-button fill-height">
    <atom-button large text class="d-flex px-2" v-if="!fab" v-bind="$attrs" v-on="$listeners">
      <atom-button
        class="atom-account-button__fab"
        small fab
        style="pointer-events: none"
        :dark="dark"
        :color="dark ? 'cs-soft-white' : 'cs-green-forest'">
        <div class="atom-account-button__letters" :class="dark ? 'text--cs-green-forest': 'white--text'">
          {{ initials }}
        </div>
      </atom-button>
      <div class="d-flex flex-column atom-account-button__content ml-4">
        <div class="atom-account-button__content_account">
          {{ $tl('atom-account-button.your-account', 'votre compte') }}
        </div>
        <div class="atom-account-button__content_given-name body-2">
          {{ user.givenName }} {{ user.familyName }}
        </div>
      </div>
    </atom-button>
    <div v-else>
      <atom-button
        class="atom-account-button__fab"
        small fab
        :dark="dark"
        :color="dark ? 'cs-soft-white' : 'cs-green-forest'"
        v-bind="$attrs"
        v-on="$listeners">
        <div class="atom-account-button__letters" :class="dark ? 'text--cs-green-forest': 'white--text'">
          {{ initials }}
        </div>
      </atom-button>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.atom-account-button {
  .atom-account-button__letters {
    height: 100%;
    width: 100%;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    user-select: none;
  }

  .atom-account-button__content_account {
    color: var(--v-primary-darken4-base);
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import AtomButton from './AtomButton.vue'
import type { User } from '../../use/auth'

export default defineComponent({
  name: 'AtomAccountButton',
  components: { AtomButton },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    dark: {
      type: Boolean as PropType<boolean>
    },
    fab: {
      type: Boolean as PropType<boolean>
    }
  },
  setup: (props) => {
    const initials = computed(() => {
      return (props.user.familyName[0] ?? '') + (props.user.givenName[0] ?? '')
    })

    return { initials }
  }
})
</script>
