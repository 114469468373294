<template>
  <v-menu class="molecule-account-menu-button" v-bind="menuAttrs">
    <template v-slot:activator="{ on, attrs }">
      <atom-account-button
        :user="user"
        :class="buttonClass"
        v-bind="{...attrs, ...$attrs}"
        v-on="on">
      </atom-account-button>
    </template>
    <v-list>
      <v-list-item :to="{name: 'user-settings'}">
        <v-list-item-title>
          {{ $tl('molecule-account-menu-button.user-parameters', 'paramètres utilisateur') }}
        </v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item :to="{name: 'advanced-settings'}" v-if="hasPermission('tenant-admin')">
        <v-list-item-title>
          {{ $tl('molecule-account-menu-button.advanced-settings', 'paramètres avancés') }}
        </v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-cog-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item :to="{name: 'subscriptions'}" v-if="!isFromTenant">
        <v-list-item-title>
          {{ $tl('molecule-account-menu-button.subscription', 'abonnement') }}
        </v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-credit-card-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="logout()">
        <v-list-item-title>
          {{ $tl('molecule-account-menu-button.logout', 'déconnexion') }}
        </v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style lang="scss" scoped>
.molecule-account-menu-button {

}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import AtomAccountButton from '../atoms/AtomAccountButton.vue'
import config from '../../config'
import type { User } from '../../use/auth'
import { useAuth } from '../../use/auth'

export default defineComponent({
  name: 'MoleculeAccountMenuButton',
  components: { AtomAccountButton },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    buttonClass: {
      type: [Object, Array] as PropType<Record<string, boolean> | string[]>
    },
    menuAttrs: {
      type: Object as PropType<Record<string, unknown>>
    }
  },
  setup: () => {
    const isFromTenant = config.tenant.tenantId !== undefined

    const { hasPermission, logout } = useAuth()

    return {
      isFromTenant,
      hasPermission,
      logout
    }
  }
})
</script>
